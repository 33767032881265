import React from "react"
import Slideshow from "../components/bb/slideshow"
import Table from "../components/bb/table"
import { css } from "@emotion/react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/bb/button"
// import { Link } from "gatsby"

let amenities = [
  ["Towels, soap, and toilet paper","Hangers","Bed linens"],
  ["Indoor fireplace", "Air conditioning", "Heating"],
  ["External security cameras on property", "Fire extinguisher", "Smoke/Carbon monoxide alarm"],
  ["Dishes", "Silverware", "Kitchen"],
  ["Free parking on site", "Long term stays allowed", "Building Staff"],
];
// append green checkmark infront of each
amenities = amenities.map((el) =>{
  return el.map((e)=>{
    return (
    <div>
      <span css={css`
        color: var(--secondary);
        font-weight: bolder;
        text-shadow: rgba(var(--secondaryalt), 0.75) 1px 2px 4px;
      `}>✔ </span>
      {e}
    </div>)
  })
});




export default function CabinTwo() {

  const bookButton = (
    <Button
      value="Book on Airbnb"
      href="https://www.airbnb.com/rooms/46318232?#availability-calendar"
      customstyle='padding: 24px 36px; margin: 24px 0;'
    />
  );

  return(
    <Layout>
      <SEO title="The Rustic Cabin" />
      <h1>Rustic cabin with natural charm</h1>
              
      <Slideshow imagefilter='cabin2' />
        
      {bookButton}
      
      <div id="section1">
        <h2>Property Description</h2>

        <p>Interested in a quiet retreat off the beat and path? Our rustic cabin is ideal for 3 guests 45 minutes east of 
          Prarie Du Chein on Crooked Creek, a Class 1 trout stream nestled in a 450 acre farm. There are 3 other cabins 
          adjacent also available for larger gatherings. The cabin is located along Crooked Creek with Mother Nature surround!
          The fireplace is gas which also provide heat for the cabin. There is a fire pit outdoors for your use.
        </p>

        <h3>Sleeping arrangements</h3>
        <p>4 guests · 2 bedrooms · 3 beds · 1 bath</p>
        
        <h3>Guest access</h3>
        <p>The cabin sits on a 450 acre farm which has ample hiking trails as well as access to Crooked Creek which runs through the property.
          Upon arrival, cabin is located IN REAR LEFT of property.</p>
        <h3>Other things to note</h3>
        <p>Guests to bring personal towels and soaps. Sheets and bedding provided and cleaned before and after stay.
          Check out is 11 AM to allow ample time for cleaning.    
        </p>
      </div>
      <div id="amenities">
        <h2>amenities</h2>
        <Table data={amenities} />
      </div>

      {bookButton}
      
    </Layout>   
  )
}
